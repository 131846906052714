import { fDatabase } from './firebase'
import firebase from "firebase/app";
import { Notification } from "app/models/notification"

function dbRef() {
  return fDatabase.ref(`/notifications/messages`)
}
function dbOrderedBy() {
  return dbRef().orderByKey()
}

export function getLatestNotifications(onValueReceivedCallback: (notifications: Array<Notification>) => any) {
  console.debug("Getting latest notifications")
  dbOrderedBy().limitToLast(20).once('value', (snapshot: firebase.database.DataSnapshot) => {
    onValueReceivedCallback(getNotificationsList(snapshot))
  })
}
export function startListeningNotifications(startAt: number, onValueReceivedCallback: (notifications: Array<Notification>) => any) {
  console.debug("Listening to notifications", startAt)
  dbOrderedBy().startAt(`${startAt}`).limitToLast(1).on('value', (snapshot: firebase.database.DataSnapshot, ctx) => {
    onValueReceivedCallback(getNotificationsList(snapshot))
  })
}
export function stopListeningNotifications(onStoppedCallback?: any) {
  dbRef().onDisconnect().cancel()
  dbRef().off('value', onStoppedCallback ?? console.debug('Stopped listening notifications'))
}

function getNotificationsList(snapshot: firebase.database.DataSnapshot): Array<Notification> {
  const results: Array<Notification> = []

  snapshot.forEach((el) => {
    if (el.exists()) results.push(new Notification(el.val()))
  })
  return results
}