export type PositionTypeString = 'DDH_DDE' | 'DDE' | 'DDE_SED' | 'SED' | 'DDH' | 'DLE' | 'DLD' | 'DV' | 'DLE_DV' | 'DDH_DLE' | 'DDH_DLD' | 'DLD_DV' | 'FALL_RAISED' | 'UPSIDE_DOWN' | 'UNK' | 'INV'

export const PositionType = {
  DDH_DDE: 'DDH_DDE',
  DDE : 'DDE',
  DDE_SED: 'DDE_SED',
  SED : 'SED',

  DDH : 'DDH',
  DLE : 'DLE',
  DLD : 'DLD',
  DV  : 'DV',

  DLE_DV: 'DLE_DV',
  DDH_DLE: 'DDH_DLD',
  DDH_DLD: 'DDH_DLD',
  DLD_DV: 'DLD_DV',

  FALL_RAISED: 'FALL_RAISED', UPSIDE_DOWN: 'UPSIDE_DOWN',
  UNK : 'UNK', INV : 'INV'
} as const

export type PositionTypes = typeof PositionType[keyof typeof PositionType]

export interface PositionMatcher {
  id: PositionTypes
  isMatch: (x: number, y: number) => boolean,
  linked?: Array<PositionTypes>
}

// Function to determine, given a date, what is the decubitus position
export const getDecubitusPositionForDate = (
  date: Date,
  hoursInterval: number = 2
): PositionTypeString => {
  // The order of positions
  const positions: PositionTypeString[] = [
    PositionType.DLD, PositionType.DDH, PositionType.DLE,
    PositionType.DLD, PositionType.DDH, PositionType.DLE
  ];

  // Calculate the total number of intervals in a 24-hour day
  const intervalsPerDay = 24 / hoursInterval;

  // Calculate the current interval index
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const totalMinutes = hours * 60 + minutes;
  const intervalIndex =
    Math.floor(totalMinutes / (hoursInterval * 60)) % intervalsPerDay;

  // Determine the position based on the interval index
  return positions[intervalIndex % positions.length];
};

// Function to determine the color of a decubitus position
export function getDecubitusPositionColor(position: PositionTypeString): string {
  switch (position) {
    case PositionType.DDH:
      return "#7D82B8";
    case PositionType.DLE:
      return "#A7C4BC";
    case PositionType.DLD:
      return "#FFDDC1";
    default:
      return "#FFFFFF";
  }
}
